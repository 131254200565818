import {KpiRangeBreachSeverity} from '@hconnect/common/types'

export type MonthlyAggregatedActualProductionKpis = {
  year: number
  /** The number of the month: {1,2,3,…,12} */
  month: number
  monthlyAggregatedActualProduction: {
    cementProduction: number
    clinkerProduction: number
  }
  hierarchyKpis?: {
    weightedKilnOperatingCoefficientPlanned: number
    weightedKilnOperatingCoefficientActual: number
    weightedKilnOperatingCoefficientDelta: number
    specificKilnHeatConsumptionPlanned: number
    specificKilnHeatConsumptionActual: number
    specificKilnHeatConsumptionDelta: number
    alternativeFuelRatePlanned: number
    alternativeFuelRateActual: number
    alternativeFuelRateDelta: number
    technicalCementPowerConsumptionKWhtPlanned: number
    technicalCementPowerConsumptionKWhtActual: number
    technicalCementPowerConsumptionKWhtDelta: number
    specificCo2EmissionPerTonOfClinkerPlanned: number
    specificCo2EmissionPerTonOfClinkerActual: number
    specificCo2EmissionPerTonOfClinkerDelta: number
    specificCo2EmissionPerTonOfCementPlanned: number
    specificCo2EmissionPerTonOfCementActual: number
    specificCo2EmissionPerTonOfCementDelta: number
    kilnReliabilityCoefficientPlanned: number
    kilnReliabilityCoefficientActual: number
    kilnReliabilityCoefficientDelta: number
    meanTimeBetweenFailurePlanned: number
    meanTimeBetweenFailureActual: number
    meanTimeBetweenFailureDelta: number
  }
}

export type KpiTrendChartData = {
  labels: string[]
  data: number[]
}

export type PlantStatusMontlyKpiTrends = {
  clinkerProduction: KpiTrendChartData
  cementProduction: KpiTrendChartData
  meanTimeBetweenFailure: KpiTrendChartData
  kilnOperatingCoefficient: KpiTrendChartData
  alternativeFuelRate: KpiTrendChartData
}

export type ReliabilityDailyKpis = {
  cementProduction?: {
    value: string
    unit?: string
    kpiBreachSeverity: KpiRangeBreachSeverity | null
  }
  clinkerProduction?: {
    value: string
    unit?: string
    kpiBreachSeverity: KpiRangeBreachSeverity | null
  }
  alternativeFuelRate?: {
    value: string
    unit?: string
    kpiBreachSeverity: KpiRangeBreachSeverity | null
  }
}
