import {dateFormatter} from '@hconnect/uikit'
import {Column, DataTable} from '@hconnect/uikit/src/lib2'
import {TFunction} from 'i18next'
import moment from 'moment'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {eventRowSx} from '../../myTasks/components/styles'
import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import type {ChecklistTemplate} from '../types'

type Props = {
  data?: ChecklistTemplate[]
  isLoading?: boolean
  emptyMessage?: string
  selectedRowId?: string
  plantId: string
  onRowClick?: (item: ChecklistTemplate) => void
}

export const ChecklistTemplatesTable = ({
  data,
  isLoading,
  emptyMessage,
  selectedRowId,
  plantId,
  onRowClick
}: Props) => {
  const {hrocPrefix} = useTranslationPrefix()
  const {t} = useTranslation()
  const timezone = usePlantTimezone()
  const columns = useMemo(
    () => generateColumns({t, hrocPrefix, timezone}),
    [t, hrocPrefix, timezone]
  )

  return (
    <DataTable<ChecklistTemplate>
      columns={columns}
      data={data}
      loading={isLoading}
      emptyMessage={emptyMessage}
      data-test-id={'checklist-templates-table'}
      onRowClick={(_, item) => onRowClick?.(item)}
      rowSx={({id}) => eventRowSx(selectedRowId === id)}
    />
  )
}

const generateColumns = ({
  t,
  hrocPrefix,
  timezone
}: {
  t: TFunction
  hrocPrefix: string
  timezone: string
}): Column<ChecklistTemplate>[] => [
  {
    key: 'name',
    label: t(`${hrocPrefix}.label.checklistName`)
  },
  {
    key: 'description',
    label: t(`${hrocPrefix}.label.description`)
  },
  {
    key: 'type',
    label: t(`${hrocPrefix}.label.type`),
    customTemplate: ({type}) => t(`${hrocPrefix}.checklist.templateType.${type}`)
  },
  {
    key: 'category',
    label: t(`${hrocPrefix}.label.category`),
    customTemplate: ({category}) => t(`${hrocPrefix}.checklist.templateCategory.${category}`)
  },
  {
    key: 'createdAt',
    label: t(`${hrocPrefix}.label.addedToTheSystem`),
    customTemplate: ({createdAt}) => dateFormatter(moment.utc(createdAt).tz(timezone))
  }
]
