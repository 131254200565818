import {KpiThresholdIcon} from '@hconnect/common/components/kpiPerformance/KpiThresholdIcon'
import {KpiScore} from '@hconnect/uikit/src/lib2'
import {Grid, Stack} from '@mui/material'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import type {PlantStatusMontlyKpiTrends, ReliabilityDailyKpis} from '../types'
import {formatKpiValue} from '../utils'

import {KpiTrendChart, KpiTrendChartSkeleton} from './KpiTrendChart'

const emptyKpiTrendData = {labels: [], data: []}

type Props = {
  dailyKpis?: ReliabilityDailyKpis
  montlyKpiTrends?: PlantStatusMontlyKpiTrends
  isMontlyKpiTrendsLoading: boolean
}

export const PlantStatusKpis: React.FC<Props> = ({
  dailyKpis,
  montlyKpiTrends,
  isMontlyKpiTrendsLoading
}) => {
  const {t, i18n} = useTranslation()
  const language = i18n.language
  const {hrocPrefix} = useTranslationPrefix()

  const kpiItems = useMemo(
    () => [
      {
        key: 'clinker-production',
        label: t(`${hrocPrefix}.plantStatus.label.clinkerProduction`),
        value: formatKpiValue(dailyKpis?.clinkerProduction?.value, language),
        unit: dailyKpis?.clinkerProduction?.unit,
        trendChartUnit: 't',
        kpiBreachSeverity: dailyKpis?.clinkerProduction?.kpiBreachSeverity,
        monthlyTrendData: montlyKpiTrends?.clinkerProduction
      },
      {
        key: 'kiln-operating-coefficient',
        label: t(`${hrocPrefix}.plantStatus.label.kilnOperatingCoefficient`),
        value: t(`${hrocPrefix}.plantStatus.label.notApplicable`),
        trendChartUnit: '%',
        monthlyTrendData: montlyKpiTrends?.kilnOperatingCoefficient
      },
      {
        key: 'alternative-fuel-rate',
        label: t(`${hrocPrefix}.plantStatus.label.alternativeFuelRate`),
        value: formatKpiValue(dailyKpis?.alternativeFuelRate?.value, language),
        unit: dailyKpis?.alternativeFuelRate?.unit,
        trendChartUnit: '%',
        kpiBreachSeverity: dailyKpis?.alternativeFuelRate?.kpiBreachSeverity,
        monthlyTrendData: montlyKpiTrends?.alternativeFuelRate
      },
      {
        key: 'cement-production',
        label: t(`${hrocPrefix}.plantStatus.label.cementProduction`),
        value: formatKpiValue(dailyKpis?.cementProduction?.value, language),
        unit: dailyKpis?.cementProduction?.unit,
        trendChartUnit: 't',
        kpiBreachSeverity: dailyKpis?.cementProduction?.kpiBreachSeverity,
        monthlyTrendData: montlyKpiTrends?.cementProduction
      },
      {
        key: 'mean-time-between-failure',
        label: t(`${hrocPrefix}.plantStatus.label.meanTimeBetweenFailure`),
        value: t(`${hrocPrefix}.plantStatus.label.notApplicable`),
        trendChartUnit: 'h',
        monthlyTrendData: montlyKpiTrends?.meanTimeBetweenFailure
      }
    ],
    [dailyKpis, montlyKpiTrends, t, hrocPrefix, language]
  )

  return (
    <Grid container spacing={2} sx={{maxWidth: '100%', maxHeight: '100%'}}>
      {kpiItems.map((kpiItem) => (
        <Grid item xs={4} key={kpiItem.key}>
          <Stack spacing={1} width="100%">
            <KpiScore
              value={kpiItem.value}
              label={kpiItem.label}
              unit={kpiItem.unit}
              valueIcon={
                kpiItem.kpiBreachSeverity ? (
                  <KpiThresholdIcon status={kpiItem.kpiBreachSeverity} variant="filled" />
                ) : undefined
              }
              size="L"
              data-test-id={`kpi-score-${kpiItem.key}`}
            />
            {isMontlyKpiTrendsLoading ? (
              <KpiTrendChartSkeleton />
            ) : (
              <KpiTrendChart
                data={kpiItem.monthlyTrendData ?? emptyKpiTrendData}
                unit={kpiItem.trendChartUnit}
              />
            )}
          </Stack>
        </Grid>
      ))}
    </Grid>
  )
}
