import {PLANT_CHECKLIST} from '@hconnect/common/hproduce/config'

import {CHECKLISTS} from '../../shared/routes/routes'

export {CHECKLISTS, PLANT_CHECKLIST}

export const PLANT_CHECKLISTS_TEMPLATES = `${CHECKLISTS}/plant/:plantId/templates`

export const RELIABILITY_ENGINEER_DASHBOARD = '/reliability-engineer-dashboard'
export const PLANT_RELIABILITY_ENGINEER_DASHBOARD = `${RELIABILITY_ENGINEER_DASHBOARD}/plant/:plantId/`
