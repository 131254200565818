import {ChecklistTemplatesTable} from '../components/ChecklistTemplatesTable'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {useChecklistTemplates} from '../hooks/api'
import type {ChecklistTemplate} from '../types'

type Props = {
  plantId: string
  selectedTemplateId?: string
  onTemplateClick?: (template: ChecklistTemplate) => void
}

export const ChecklistTemplatesContainer: React.FC<Props> = ({
  plantId,
  selectedTemplateId,
  onTemplateClick
}) => {
  const {data, isLoading, isFetching, isRefetching, isError, refetch} = useChecklistTemplates({
    plantId
  })

  return (
    <DataContentWrapper
      isLoading={isLoading}
      isError={isError}
      data={data}
      retryFunction={() => void refetch()}
      isRefetching={isRefetching}
      isNoData={(data) => !data}
      progressSx={{color: 'primary.main'}}
    >
      <ChecklistTemplatesTable
        data={data}
        isLoading={isFetching}
        selectedRowId={selectedTemplateId}
        plantId={plantId}
        onRowClick={onTemplateClick}
      />
    </DataContentWrapper>
  )
}
