import {CardBox} from '@hconnect/uikit/src/lib2'
import {CalendarTodayOutlined, ShowChart} from '@mui/icons-material'
import {Box, Chip, Typography} from '@mui/material'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {PlantStatusKpis} from '../components'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {cardBoxSizes} from '../consts'
import {useDailyPerformanceKpis, useMonthlyAggregatedKpis} from '../hooks/api'
import {mapToPlantStatusMontlyKpiTrends} from '../mappers'

type Props = {
  plantId: string
}

const AGGREGATION_MONTHS_COUNT = 6

export const PlantStatusKpisContainer: React.FC<Props> = ({plantId}) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const timezone = usePlantTimezone()
  const to = moment.utc().tz(timezone).startOf('month')
  const from = moment(to).subtract(AGGREGATION_MONTHS_COUNT, 'months')

  const {
    data: monthlyKpiTrends,
    isLoading: isMonthlyKpisLoading,
    isError: isMontlyKpisError,
    isRefetching: isMonthlyKpisRefetching,
    refetch
  } = useMonthlyAggregatedKpis({plantId, from, to}, {select: mapToPlantStatusMontlyKpiTrends})

  const startOfYesterday = moment.utc().tz(timezone).subtract(1, 'days').startOf('day')
  const endOfYesterday = moment(startOfYesterday).endOf('day')
  const {
    data: yesterdayKpis,
    isLoading: isYesterdayKpisLoading,
    isError: isYesterdayKpisError,
    isRefetching: isYesterdayKpisRefetching,
    refetch: refetchYesterdayKpis
  } = useDailyPerformanceKpis({plantId, from: startOfYesterday, to: endOfYesterday})

  return (
    <CardBox sx={cardBoxSizes['L']} data-test-id="plant-status-kpis-card">
      <Box display="flex" flexDirection="row">
        <Box flex={1}>
          <Typography variant="h3" mb={2}>
            {t(`${hrocPrefix}.plantStatus.cardTitle`)}
          </Typography>
        </Box>
        <Chip
          icon={<CalendarTodayOutlined />}
          label={t(`${hrocPrefix}.label.yesterday`)}
          size="small"
          sx={{mx: 1}}
        />
        <Chip
          icon={<ShowChart />}
          label={t(`${hrocPrefix}.label.monthsCount`, {count: AGGREGATION_MONTHS_COUNT})}
          size="small"
          sx={{mx: 1}}
        />
      </Box>
      <DataContentWrapper
        isLoading={isMonthlyKpisLoading && isYesterdayKpisLoading}
        isError={isMontlyKpisError || isYesterdayKpisError}
        isRefetching={isMonthlyKpisRefetching || isYesterdayKpisRefetching}
        data={yesterdayKpis}
        isNoData={() => !yesterdayKpis && !monthlyKpiTrends}
        retryFunction={() => {
          void refetch()
          void refetchYesterdayKpis()
        }}
        progressSx={{color: 'primary.main'}}
      >
        <PlantStatusKpis
          dailyKpis={yesterdayKpis}
          montlyKpiTrends={monthlyKpiTrends}
          isMontlyKpiTrendsLoading={isMonthlyKpisLoading}
        />
      </DataContentWrapper>
    </CardBox>
  )
}
