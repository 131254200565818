import {AnalysisListType} from '@hconnect/common/types'
import {getMaintainAnalysisListUrl} from '@hconnect/common/utils'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {CalendarTodayOutlined, ChevronRight} from '@mui/icons-material'
import {Box, Chip, Link, Typography} from '@mui/material'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

import {useRcfaAvailability} from '../../performanceDashboard/hooks/useRcfaAvailability'
import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {RcfaSummary} from '../components/RcfaSummary'
import {cardBoxSizes, placeholderWrapperSizes} from '../consts'

type Props = {
  plantId: string
}

export const RcfaContainer = ({plantId}: Props) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const timezone = usePlantTimezone()
  const startOfToday = moment.utc().tz(timezone).startOf('day')
  const thirtyDaysAgo = moment(startOfToday).subtract(30, 'day')
  const endOfToday = moment(startOfToday).endOf('day')
  const timeRange = {
    startDate: thirtyDaysAgo.toISOString(),
    endDate: endOfToday.toISOString()
  }

  const {data, isLoading, isError, isRefetching, refetch} = useRcfaAvailability(plantId, timeRange)

  const linkPath = getMaintainAnalysisListUrl(plantId, AnalysisListType.MyPlant)

  return (
    <Link
      href={linkPath}
      data-test-id="rcfa-summary-card-link-to-maintain"
      sx={(theme) => ({
        textDecoration: 'none',
        '&:hover': {
          '& .card-chevron, .card-name': {
            color: theme.palette.primary.main
          }
        }
      })}
    >
      <CardBox sx={cardBoxSizes['S']} data-test-id="rcfa-summary-card">
        <Box display="flex" flexDirection="row">
          <Box flex={1}>
            <Typography variant="h3" mb={2}>
              {t(`${hrocPrefix}.rcfaSummary.cardTitle`)}
            </Typography>
          </Box>
          <Chip
            icon={<CalendarTodayOutlined />}
            label={t(`${hrocPrefix}.label.last30days`)}
            size="small"
            sx={{mx: 1}}
          />
          <Box className="card-chevron">
            <ChevronRight />
          </Box>
        </Box>
        <DataContentWrapper
          isLoading={isLoading}
          isError={isError}
          isRefetching={isRefetching}
          data={data}
          retryFunction={() => void refetch()}
          progressSx={{color: 'primary.main'}}
          placeholderWrapperSx={placeholderWrapperSizes['S']}
        >
          {data && <RcfaSummary {...data} />}
        </DataContentWrapper>
      </CardBox>
    </Link>
  )
}
