import moment from 'moment'

import type {
  KpiTrendChartData,
  MonthlyAggregatedActualProductionKpis,
  PlantStatusMontlyKpiTrends
} from '../types'

export const mapToPlantStatusMontlyKpiTrends = (
  data: MonthlyAggregatedActualProductionKpis[]
): PlantStatusMontlyKpiTrends => {
  const clinkerProduction: KpiTrendChartData = {labels: [], data: []}
  const cementProduction: KpiTrendChartData = {labels: [], data: []}
  const meanTimeBetweenFailure: KpiTrendChartData = {labels: [], data: []}
  const kilnOperatingCoefficient: KpiTrendChartData = {labels: [], data: []}
  const alternativeFuelRate: KpiTrendChartData = {labels: [], data: []}

  data.forEach((item) => {
    const label = moment.utc([item.year, item.month - 1]).toISOString()
    clinkerProduction.labels.push(label)
    cementProduction.labels.push(label)
    meanTimeBetweenFailure.labels.push(label)
    kilnOperatingCoefficient.labels.push(label)
    alternativeFuelRate.labels.push(label)

    clinkerProduction.data.push(item.monthlyAggregatedActualProduction?.clinkerProduction ?? 0)
    cementProduction.data.push(item.monthlyAggregatedActualProduction?.cementProduction ?? 0)
    meanTimeBetweenFailure.data.push(item.hierarchyKpis?.meanTimeBetweenFailureActual ?? 0)
    kilnOperatingCoefficient.data.push(
      item.hierarchyKpis?.weightedKilnOperatingCoefficientActual ?? 0
    )
    alternativeFuelRate.data.push(item.hierarchyKpis?.alternativeFuelRateActual ?? 0)
  })

  return {
    clinkerProduction,
    cementProduction,
    meanTimeBetweenFailure,
    kilnOperatingCoefficient,
    alternativeFuelRate
  }
}
