import {dateFormatter, type PaginationOptions, timeFormatter} from '@hconnect/uikit'
import {Column, DataTable} from '@hconnect/uikit/src/lib2'
import {TFunction} from 'i18next'
import moment, {Moment} from 'moment'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {eventRowSx} from '../../myTasks/components/styles'
import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {SortOrder, type ChecklistDoneForTemplateDto} from '../types'

type Props = {
  data?: ChecklistDoneForTemplateDto[]
  isLoading?: boolean
  emptyMessage?: string
  selectedRowId?: string
  sortOrder: SortOrder
  paginationOptions: PaginationOptions
  onRowClick: (item: ChecklistDoneForTemplateDto) => void
  onSortChange: (columnKey: keyof ChecklistDoneForTemplateDto, sortOrder: SortOrder) => void
}

export const CompletedChecklistsTable = ({
  data,
  isLoading,
  emptyMessage,
  selectedRowId,
  sortOrder,
  onRowClick,
  onSortChange,
  paginationOptions
}: Props) => {
  const {hrocPrefix} = useTranslationPrefix()
  const {t} = useTranslation()

  const {plantId} = useParams()
  if (!plantId) throw new Error('Missing plantId prop')

  const timezone = usePlantTimezone()
  const columns = useMemo(
    () => generateColumns({t, hrocPrefix, timezone}),
    [t, hrocPrefix, timezone]
  )

  return (
    <DataTable<ChecklistDoneForTemplateDto>
      columns={columns}
      data={data}
      loading={isLoading}
      emptyMessage={emptyMessage}
      data-test-id={'completed-checklists-table'}
      onRowClick={(_, item) => onRowClick(item)}
      rowSx={({id}) => eventRowSx(selectedRowId === id)}
      sortedBy="completedAt" // BE only support one column and doesn't require its name in the params
      sortDirection={sortOrder === SortOrder.ASCENDING ? 'asc' : 'desc'}
      onSort={(_, columnKey: keyof ChecklistDoneForTemplateDto) => {
        onSortChange(
          columnKey,
          sortOrder === SortOrder.ASCENDING ? SortOrder.DESCENDING : SortOrder.ASCENDING
        )
      }}
      paginationOptions={paginationOptions}
    />
  )
}

const generateColumns = ({
  t,
  hrocPrefix,
  timezone
}: {
  t: TFunction
  hrocPrefix: string
  timezone: string
}): Column<ChecklistDoneForTemplateDto>[] => [
  {
    key: 'completedAt',
    label: t(`${hrocPrefix}.label.dateTime`),
    customTemplate: ({completedAt}) => formatDateAndTime(moment.utc(completedAt).tz(timezone)),
    sortable: true
  },
  {
    key: 'preparedBy',
    label: t(`${hrocPrefix}.label.preparedBy`)
  }
]

// TODO: replace with new moment formatter with separator when it will be available
const formatDateAndTime = (date: Moment) => `${dateFormatter(date)} - ${timeFormatter(date)}`
